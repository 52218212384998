import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Modal from "react-responsive-modal";
import { DateTime } from "luxon";
import { SearchContext } from "../../context/SearchContext";
import BackButton from "../BackButton";
import Traveler from "./Traveler";
import validationErrors from "../../helpers/validationErrors";
import Trip from "../result/Trip";
import TotalDisplay from "../result/total";
import axiosCall from "../../hooks/axiosCall";
import { ROUTE_TYPE_ONE_WAY } from "../../constants";

moment.suppressDeprecationWarnings = true;

const GuestInfo = () => {
  const {
    selectedTrip,
    travelerCount,
    travelerInfo,
    setTravelerInfo,
    routeType,
    total,
  } = useContext(SearchContext);
  const [err, setErr] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [unneededBoardingPasses, setUnneededBoardingPasses] = useState([]);

  const history = useNavigate();

  //const travelers = [];
  //for (let i = 0; i < travelerCount; i++) {
  //	travelers.push(<Traveler key={`traveler${i}`} idx={i} />);
  //}
  const travelers = [...Array(parseInt(travelerCount)).keys()];

  const calcularEdad = (fechaNacimiento) => {
    const añoNacimiento = parseInt(fechaNacimiento.year);
    const mesNacimiento = parseInt(fechaNacimiento.month) - 1; // Restamos 1 porque los meses en JavaScript van de 0 a 11
    const diaNacimiento = parseInt(fechaNacimiento.day);

    const fechaNacimientoCompleta = new Date(
      añoNacimiento,
      mesNacimiento,
      diaNacimiento
    );

    return moment().diff(fechaNacimientoCompleta, "years"); //edad
  };

  const formValid = () => {
    let isValid = true;
    const primaryTraveler = travelerInfo[0];
    const errorMsg = (msg) => {
      setErr(msg);
      isValid = false;
      return {
        noErrors: false,
      };
    };

    if (!primaryTraveler.email) return errorMsg(validationErrors.emailBlank);
    if (primaryTraveler.email) {
      const pattern =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (!pattern.test(primaryTraveler.email))
        return errorMsg(validationErrors.emailInvalid);
    }
    if (!primaryTraveler.phone) return errorMsg(validationErrors.phoneBlank);
    if (total === 0) {
      return errorMsg(
        "Please select a birthdate for at least 1 Adult (Traveler 1)"
      );
    }

    if (
      primaryTraveler.currentFares.rateType === "Infant" ||
      primaryTraveler.currentFares.rateType === "Child" ||
      (primaryTraveler.currentFares.rateType === "Adult" &&
        calcularEdad(primaryTraveler.birthdate) < 18)
    ) {
      return errorMsg("The first passenger must be an adult");
    }

    let i = 0;
    const len = travelerInfo.length;
    const unneededBoardingPasses = [];
    for (i; i < len; i++) {
      const traveler = travelerInfo[i];
      if (i > 0 && traveler.cost === 0) {
        unneededBoardingPasses.push(i);
        continue;
      }
      if (!traveler.firstName) return errorMsg(validationErrors.firstNameBlank);
      if (!traveler.lastName) return errorMsg(validationErrors.lastNameBlank);
      if (!traveler.birthdate)
        return errorMsg("Please enter all traveler birthdates (Ages 3 and up)");
      if (!traveler.passportId) return errorMsg(validationErrors.passportBlank);
    }

    return {
      noErrors: isValid,
      unneededBoardingPasses,
    };
  };

  const onNextClick = async (e) => {
    e.preventDefault();
    const validCheck = formValid();
    if (validCheck.noErrors) {
      if (validCheck.unneededBoardingPasses.length === 0) {
        await consultMemberInfo(travelerInfo);
        history("/order-review");
      } else {
        setUnneededBoardingPasses(validCheck.unneededBoardingPasses);
        setShowModal(true);
      }
    }
  };

  const consultMemberInfo = async (travelerInfo) => {
    let members = [];
    for (var traveler of travelerInfo) {
      var member = {
        firstName: traveler.firstName,
        lastName: traveler.lastName,
        phoneNumber: traveler.phone,
        email: traveler.email,
        identification: traveler.passportId,
        dateBirth: DateTime.fromObject(traveler.birthdate).toISODate(),
        ticketClass: traveler.ticketClass.value
      };
      members.push(member);
    }
    const result = await axiosCall("member/getbyidentification", "POST", members);

    for (var item of travelerInfo) {
      var exist = result.data.find(x => x.identification === item.passportId);

      if (exist) {
        item.memberInfo = exist;
      }
    }
  }

  const removeUnneededBoardingPassesAndContinue = () => {
    const cleanInfantArray = travelerInfo.filter((item) => {
      return item.cost !== 0;
    });

    setTravelerInfo(cleanInfantArray);
    history("/order-review");
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {travelerInfo && travelerInfo.length > 0 && (
        <>
          <div className="vw vw-guest">
            <BackButton link="/" text="New Search" />
            <div className="container">
              <h2 className="is-title">Your trip summary</h2>
              <h4>
                <span>{routeType}</span> {routeType === ROUTE_TYPE_ONE_WAY ? `${selectedTrip.originPort} to ${selectedTrip.destinationPort}` : `${selectedTrip.depart.originPort} to ${selectedTrip.depart.destinationPort} and back`}
              </h4>
              <p className="pricesShown">
                <span>All Prices Shown in US dollars</span> - You will have the
                opportunity to review your booking before it is completed.
              </p>
              <div className="intro">
                {selectedTrip?.ret ? (
                  <div className="result clearfix oneway-display topguest">
                    <div className="top topguest">
                      <div className="detail txt-center ">
                        <Trip tripModel={selectedTrip.depart} />
                        <Trip
                          tripModel={selectedTrip.ret}
                          indexReturn={selectedTrip.ret}
                        />
                      </div>
                    </div>
                    <TotalDisplay></TotalDisplay>
                  </div>
                ) : (
                  <div className="result clearfix oneway-display ">
                    <div className="top topguest">
                      <div className="detail txt-center one-way">
                        <Trip tripModel={selectedTrip} />
                      </div>
                    </div>
                    <TotalDisplay></TotalDisplay>
                  </div>
                )}

                <div className="usefulInfo">
                  <p>Useful Information</p>
                  <ul>
                    {selectedTrip.disclaimer && (
                      <li><span>&bull;</span>{selectedTrip.disclaimer}</li>
                    )}
                    <li>
                      <span>&bull;</span> Carry-on &amp; personal item (access
                      to overhead bin)
                    </li>
                    <li>
                      <span>&bull;</span>{" "}
                      <a
                        href="https://www.roatanferry.com/our-terminals/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Services available
                      </a>{" "}
                      on-board the Ferry
                    </li>
                    <li>
                      <span>&bull;</span> Checkin before time before departure
                    </li>
                    <li>
                      <span>&bull;</span> Galaxy Wave policies
                    </li>
                  </ul>
                </div>
              </div>
              <h2 className="is-title">Traveler details</h2>
              <p>Please enter all traveler info (All fields are required)</p>
              <div className="container no-padding">
                {travelers.map((item) => {
                  return <Traveler key={`traveler${item}`} idx={item} />;
                })}
              </div>
              <div className="container no-padding">
                <hr />
                {err && (
                  <p
                    data-cy="error"
                    className="err err-text"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    {err}
                  </p>
                )}
                <button
                  onClick={(e) => onNextClick(e)}
                  data-cy="nextBtn"
                  className="btn-blue btn-lg btnGuestContinue"
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <Modal
            open={showModal}
            onClose={onCloseModal}
            center
            showCloseIcon={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            classNames={{
              modal: "ModalCustomStyles",
              overlay: "OverlayCustomStyles",
            }}
          >
            <h2>Unneeded Boarding Pass</h2>
            <p data-cy="unneeded-boarding-txt">
              You have {unneededBoardingPasses.length} ticket
              {unneededBoardingPasses.length > 1 ? "s" : ""} with{" "}
              {unneededBoardingPasses.length > 1 ? "travelers" : "a traveler"}{" "}
              below the age of 3,{" "}
              {unneededBoardingPasses.length > 1 ? "they" : "it"} will be
              removed.
            </p>
            <p>Travelers below the age of 3 do not require boarding passes.</p>
            <ol>
              <br />
              <button
                data-cy="close-modal"
                className="btn-blue float-right"
                onClick={() => removeUnneededBoardingPassesAndContinue()}
              >
                OK
              </button>
              <button
                data-cy="close-modal"
                className="btn-blue float-right margin-right"
                onClick={() => onCloseModal()}
              >
                REVIEW
              </button>
            </ol>
          </Modal>
        </>
      )}
    </>
  );
};

export default GuestInfo;
