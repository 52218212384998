import React, { useContext, useState, useEffect } from "react";

import Select from "react-select";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import Modal from "react-responsive-modal";

import {
  ROUTE_TYPE_ONE_WAY,
  ROUTE_TYPE_ROUND_TRIP,
  AGENCY_ID,
  ONE_WAY_TRIP_TYPE,
  ROUND_WAY_TRIP_TYPE,
} from "../../constants";
import { SearchContext } from "../../context/SearchContext";
import "../../sass/components/_reactDateTime.scss";
import "./search.css";
import reactSelectStyle from "../../styles/reactSelectStyles";
import axiosCall from "../../hooks/axiosCall";

const Search = ({ route }) => {
  const [routeValue, setRouteValue] = useState(null);
  const {
    allRoutes,
    selectedRoute,
    setSelectedRoute,
    travelerCount,
    setTravelerCount,
    errorMessage,
    setShowTrips,
    routeType,
    setRouteType,
    departureDate,
    setDepartureDate,
    returnDate,
    setReturnDate,
    setAlltrips,
    findRoute
  } = useContext(SearchContext);
  const [depart, setDepart] = useState(new Date());
  const [returnDate1, setReturn] = useState(new Date());
  const [showModal, setShowModal] = useState(false);

  const search = async (e) => {
    e.preventDefault();
    await findRoute();
  };


  const parsedRoutes = allRoutes.map((route, index) => {
    return {
      value: route.originPortId + "/" + route.destinationPortId,
      label: route.originPort + " → " + route.destinationPort,
      id: `${route.originPort.toLowerCase()}-${route.destinationPort.toLowerCase()}`,
    };
  });

  const onRouteTypeClicked = (e, routeType) => {
    e.preventDefault();
    setRouteType(routeType);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  let yesterday = moment().subtract(1, "day");
  let returningDate = moment(departureDate).subtract(1, "day");
  const validDeparture = (current) => {
    const isAfterYesterday = current.isAfter(yesterday);
    const availableDays = getAvailableDays(); 
    const dayOfWeek = current.day();
    const currentDateStr = current.format('YYYY-MM-DD');
    
    // Verificar si la fecha está en las fechas específicas
    const specificDates = getSpecificDates();
    const isSpecificDate = specificDates.includes(currentDateStr);
    
    // Permitir la fecha si es una fecha específica O si es un día de la semana disponible
    return isAfterYesterday && (isSpecificDate || availableDays.includes(dayOfWeek));
  };

  const validReturning = (current) => {
    const isAfterYesterday = current.isAfter(returningDate);
    const availableDays = getReturnAvailableDays();
    const dayOfWeek = current.day();
    const currentDateStr = current.format('YYYY-MM-DD');
    
    // Verificar si la fecha está en las fechas específicas de retorno
    const specificReturnDates = getSpecificReturnDates();
    const isSpecificDate = specificReturnDates.includes(currentDateStr);
    
    // Permitir la fecha si es una fecha específica O si es un día de la semana disponible
    return isAfterYesterday && (isSpecificDate || availableDays.includes(dayOfWeek));
  }

  const getSpecificDates = () => {
    try {
      const tripSchedules = JSON.parse(process.env.REACT_APP_TRIP_SCHEDULES);
      return tripSchedules.trips[routeValue?.id]?.specificDates || [];
    } catch (error) {
      return [];
    }
  };

  const getSpecificReturnDates = () => {
    try {
      const tripSchedules = JSON.parse(process.env.REACT_APP_TRIP_SCHEDULES);
      return tripSchedules.trips[routeValue?.id]?.specificReturnDates || [];
    } catch (error) {
      return [];
    }
  };

  const getAvailableDays = () => {
    try {
      const tripSchedules = JSON.parse(process.env.REACT_APP_TRIP_SCHEDULES);
      return tripSchedules.trips[routeValue?.id]?.availableDays || [1, 2, 3, 4, 5, 6, 0];
    } catch (error) {
      return [1, 2, 3, 4, 5, 6, 0]; // valor por defecto
    }
  };

  const getReturnAvailableDays = () => {
    try {
      const tripSchedules = JSON.parse(process.env.REACT_APP_TRIP_SCHEDULES);
      return tripSchedules.trips[routeValue?.id]?.returnDays || [1, 2, 3, 4, 5, 6, 0];
    } catch (error) {
      return [1, 2, 3, 4, 5, 6, 0]; // valor por defecto
    }
  };

  useEffect(() => {
    if (route && route !== "" && allRoutes.length > 0) {
      const mapped = allRoutes.map((route) => {
        return {
          label: route.originPort + " → " + route.destinationPort,
          id: `${route.originPort.toLowerCase()}-${route.destinationPort.toLowerCase()}`,
          value: route.originPortId + "/" + route.destinationPortId,
        };
      });
      let finded = mapped.find((r) => r.id == route.toLowerCase());
      if (finded) {
        setRouteValue(finded);
        setSelectedRoute(finded.value);
      }
    }

  }, [route, allRoutes]);

  const renderDay = (props, currentDate) => {
    const availableDays = getAvailableDays();
    const dayOfWeek = currentDate.day();
    const currentDateStr = currentDate.format('YYYY-MM-DD');
    const specificDates = getSpecificDates();
    
    // Verificar si es un día disponible por día de semana o fecha específica
    const isAvailableByDay = availableDays.includes(dayOfWeek);
    const isSpecificDate = specificDates.includes(currentDateStr);
    const isAfterYesterdayDate = currentDate.isAfter(yesterday);
    
    const isAvailable = (isAvailableByDay || isSpecificDate) && isAfterYesterdayDate;

    // Si no está disponible, añadimos la clase rdtDisabled para prevenir la selección
    let className = isAvailable ? 'rdtDay available-day' : 'rdtDay rdtDisabled';
    if (props.className.includes('rdtActive')) {
      className += ' rdtActive';
    }

    return (
      <td {...props} className={className}>
        {currentDate.date()}
      </td>
    );
  };

  const renderReturnDay = (props, currentDate) => {
    const availableDays = getReturnAvailableDays();
    const dayOfWeek = currentDate.day();
    const currentDateStr = currentDate.format('YYYY-MM-DD');
    const specificReturnDates = getSpecificReturnDates();
    
    // Verificar si es un día disponible por día de semana o fecha específica
    const isAvailableByDay = availableDays.includes(dayOfWeek);
    const isSpecificDate = specificReturnDates.includes(currentDateStr);
    const isAfterReturningDate = currentDate.isAfter(returningDate);
    
    const isAvailable = (isAvailableByDay || isSpecificDate) && isAfterReturningDate;

    let className = isAvailable ? 'rdtDay available-day' : 'rdtDay rdtDisabled';
    if (props.className.includes('rdtActive')) {
      className += ' rdtActive';
    }

    return (
      <td {...props} className={className}>
        {currentDate.date()}
      </td>
    );
  };

  useEffect(() => {
    setReturn(moment(returnDate).toDate());
  }, [returnDate])

  useEffect(() => {
    setDepart(moment(departureDate).toDate());
  }, [departureDate])

  return (
    <aside id="quickQuote" className="quickQuote">
      <form onSubmit={(e) => search(e)}>
        <div className="type">
          <button
            data-cy="tab-one-way"
            className={routeType === ROUTE_TYPE_ONE_WAY ? 'selected' : ''}
            onClick={(e) => {
              onRouteTypeClicked(e, ROUTE_TYPE_ONE_WAY);
            }}
          >
            <img src="/img/qq/single-arrow.png" alt="" />
            One Way
          </button>
          <button
            data-cy="tab-round-trip"
            className={routeType === ROUTE_TYPE_ROUND_TRIP ? 'selected' : ''}
            onClick={(e) => {
              onRouteTypeClicked(e, ROUTE_TYPE_ROUND_TRIP);
            }}
          >
            <img src="/img/qq/double-arrow.png" alt="" />
            Round Trip
          </button>
        </div>
        <div className="criteria">
          <div className="form-group xl-size">
            <label htmlFor="route">Route:</label>
            <Select
              styles={reactSelectStyle}
              isSearchable={false}
              value={routeValue}
              name="route"
              searchable={false}
              clearable={false}
              options={parsedRoutes}
              onChange={(e) => {
                setRouteValue(e);
                setSelectedRoute(e.value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">DEPART:</label>
            <Datetime
              inputProps={{
                'data-cy': 'depart-date-select',
                className: 'datetime',
              }}
              initialValue={depart}
              value={depart}
              isValidDate={(current) => validDeparture(current)}
              dateFormat="MM/DD/YYYY"
              locale="es-HN"
              timeFormat={false}
              onChange={(current) => setDepartureDate(current)}
              closeOnSelect
              renderDay={renderDay}
            />
          </div>

          <div
            className={routeType === ROUTE_TYPE_ONE_WAY ? 'hide' : 'form-group'}
          >
            <label htmlFor="">RETURN:</label>
            <Datetime
              dateFormat="MM/DD/YYYY"
              initialValue={returnDate1}
              value={returnDate1}
              timeFormat={false}
              isValidDate={(current) => validReturning(current)}
              onChange={(current) => setReturnDate(current)}
              closeOnSelect
              renderDay={renderReturnDay}
            />
          </div>
          <div className="form-group travellers-group">
            <label htmlFor="">Travelers:</label>
            <input
              type="number"
              min="1"
              value={travelerCount}
              onChange={(e) => setTravelerCount(e.target.value)}
            />
          </div>
          <input
            data-cy="search"
            type="submit"
            value="Search"
            className="qqSubmitBtn btn-blue"
            disabled={!selectedRoute}
          />
          <p className="contactInfo boardingPassAgeRequirementNotice search-traveler-select-info-txt">
            *Traveler count required for ages 3 and up{' '}
            <i
              onClick={() => setShowModal(true)}
              className="fas fa-info-circle info-btn"
            />
          </p>
        </div>
        {errorMessage && <div className="errMsg">{errorMessage}</div>}
      </form>

      <Modal
        open={showModal}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          modal: 'ModalCustomStyles',
          overlay: 'OverlayCustomStyles',
        }}
      >
        <h2>Traveler Boarding Passes</h2>
        <p>Travelers 2 years old and younger do not require boarding passes.</p>
        <p>
          If any of your travelers are 2 years old or younger, you may omit them
          from your traveler count.
        </p>
        <ol>
          <br />
          <button
            data-cy="close-modal"
            className="btn-blue"
            onClick={() => onCloseModal()}
          >
            OK
          </button>
        </ol>
      </Modal>
    </aside>
  );
};

export default Search;
